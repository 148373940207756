export interface StringObject {
    [key: string]: string;
}


export type FormatLinkType = {
    Format(route: string, values: StringObject): string;
}


const useLinks = (): FormatLinkType => {

    const Format = (route: string, values: StringObject): string => {
        let keys = Object.keys(values)
        for (let key of keys) {
            route = route.replaceAll(":" + key, values[key])
        }
        return route
    }

    return {Format}
}

export default useLinks;