import {createClient, Entry, EntryFieldTypes} from 'contentful';

// Create a Contentful client using environment variables
const client = createClient({
    space: import.meta.env.VITE_CONTENTFUL_SPACE_ID as string,
    accessToken: import.meta.env.VITE_CONTENTFUL_ACCESS_TOKEN as string,
});

export type FullWidthTextEntry = {
    contentTypeId: string;
    fields: {
        entryName: EntryFieldTypes.Text;
        content: EntryFieldTypes.Text;
    };
}

export type KnownEntryTypes = Entry<FullWidthTextEntry>;

export type PageEntry = {
    contentTypeId: string;
    fields: {
        entryName: string;
        pageTitle: string;
        metaDescription: string;
        components: KnownEntryTypes[];
    };
}

export const FetchPage = async (pageId: string): Promise<Entry<PageEntry> | null> => {
    try {
        return await client.getEntry<PageEntry>(pageId); // Specify the type for getEntry
    } catch (error) {
        console.error(`Error fetching page with ID ${pageId}:`, error);
        return null;
    }
};