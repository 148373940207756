import React, {useEffect, useState} from "react";
import MessageTileComponent from "../MessageTileComponent";
import PaginationComponent from "../PaginationComponent";
import {ApolloError} from "@apollo/client";
import {XCircleIcon} from "@heroicons/react/20/solid";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import {P2kMessage, PagedMessages} from "../../../models/message.ts";
import {useSelector} from "react-redux";
import {RootState} from "../../../App/Redux/rootState.ts";

interface Props {
    data: PagedMessages | undefined;
    loading: boolean;
    error: ApolloError | undefined;
    appendRealtime?: boolean;
    title?: string;
    description?: string;
}

const MessageListComponent: React.FC<Props> = ({data, loading, error, appendRealtime, title, description}) => {
    const searchParams = new URLSearchParams(location.search);
    const feedMessage = useSelector((state: RootState) => state.pk2.feedMessage);
    const page = parseInt(searchParams.get('page') ?? "") || 1;
    const loadingArr = Array.from({ length: 10 }, (_) => "")
    const [localData, setLocalData] = useState<P2kMessage[]>([])
    const [imported, setImported] = useState<boolean>(false)

    useEffect(() => {
        if (data?.Messages) {
            const tmpArr: P2kMessage[] = []
            data.Messages.forEach((msg: P2kMessage | null) => {
                if (msg == null) return
                tmpArr.push(msg)
            });
            setLocalData([...localData, ...tmpArr])
        }
        if (!loading && !error) {
            console.log("Imported")
            setImported(true)
        }

    }, [data, loading, error]);

    useEffect(() => {
        if (appendRealtime && page === 1) {
            if (feedMessage != null){
                setLocalData([feedMessage, ...localData])
            }
        }
    }, [feedMessage, appendRealtime]);


    if (loading) {
        return (
                <div className="mt-24 mb-24 mx-auto max-w-7xl px-2 sm:px-4 lg:px-8 h-auto">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {loadingArr.map((_, index) => (
                            <MessageTileComponent loading={true} data={null} key={index}/>
                        ))}
                    </div>
                </div>
        )
    }

    if (error) {
        return (
            <div className="mt-24 mb-24 mx-auto max-w-7xl px-2 sm:px-4 lg:px-8 h-auto">
                <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">Er ging iets mis.</h3>
                            <div className="mt-2 text-sm text-red-700">
                                De website had een probleem met het verwerken van uw verzoek. Probeer het later nog eens.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="mb-24 mx-auto max-w-7xl px-2 sm:px-4 lg:px-8 h-auto lg:mt-0">
            <div>
                <div className="mb-12 mt-20">
                    {title && <h1 className="text-3xl sm:text-4xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200">{title}</h1>}
                    {description && <span className="text-l text-slate-700 dark:text-slate-400">{description}</span>}
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {localData.map((msg, index) => (
                        <MessageTileComponent data={msg} key={msg?.ID ?? index} loading={false}/>
                    ))}
                    {localData.length === 0 && imported && <div className="rounded-md bg-blue-50 p-4 mt-40">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                <p className="text-sm text-blue-700">Geen berichten gevonden voor deze zoek opdracht.</p>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <PaginationComponent total={data!.Total} pageSize={data!.Limit} page={page} />
        </div>
    )
}

export default MessageListComponent