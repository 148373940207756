import React, {ReactElement} from "react";
import FireIcon from "../../../svg/fire-solid.svg?react";
import PoliceIcon from "../../../svg/siren-on-duotone.svg?react";
import AmbuIcon from "../../../svg/truck-medical-solid.svg?react";
import HelicopterIcon from "../../../svg/helicopter-solid.svg?react";
import UnknownIcon from "../../../svg/comment-solid.svg?react";
import BoatIcon from "../../../svg/boat-solid.svg?react";
import {P2kExtraReceiver} from "../../../models/message.ts";
import useLinks from "../../../Services/Hooks/useLinks";
import {ROUTE} from "../../../Services/Router/routes.tsx";

interface Props {
    data: P2kExtraReceiver;
}

const GetIcon = (selector: string): React.ReactElement => {
    let element: ReactElement;
    switch (selector) {
        case "POL":
            element = <PoliceIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />
            break;
        case "AMBU":
                element = <AmbuIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />
            break;
        case "LFL":
        case "LifeLiner":
            element = <HelicopterIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />
            break;
        case "BRW":
            element = <FireIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />
            break;
        case "KNRM":
        case "RB":
        case "BRUG":
        case "KWC":
            element = <BoatIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />
            break;
        default:
            element = <UnknownIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />
    }
    return element;
}

const MessageTileComponent: React.FC<Props> = ({data}) => {
    const links = useLinks()
    const functionMapping= new Map<string, string>([
        ["BRW", "Brandweer"],
        ["GMK", "Meldkamer"],
        ["AMBU", "Ambulance"],
        ["POL", "Politie"],
        ["LFL", "Lifeliner"],
        ["LifeLiner", "Lifeliner"],
        ["AMBU/BRW/POL", "Amnulance/Brandweer/Politie"],
        ["BRW/AMBU", "Brandweer/Amnulance"],
        ["RWS", "Rijkswaterstaat"],
        ["KNRM", "KNRM"],
        ["KMAR", "Koninklijke Marechaussee"],
        ["DARES", "DARES"],
        ["MKA", "MKA"],
        ["KWC", "KWC"],
        ["RB", "Reddingsbrigade"],
        ["BRUG", "Brug"],
        ["OVERIG", "Overig"],
    ]);

    return (
        <div
            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:border-gray-400 dark:bg-gray-800 dark:border-slate-700">
            <div className="flex-shrink-0 flex">
                {GetIcon(data.Discipline)}
            </div>
            <div className="min-w-0 flex-1">
                <a href={links.Format(ROUTE.CAP_CODE, {"cap": data.CapCode})} className="text-sm font-medium text-gray-900 dark:text-white">
                    {data.CapCode}
                </a>
                <p className="truncate text-sm text-gray-500 dark:text-gray-300">
                    {functionMapping.get(data.Discipline)} {data.Function ? ` - ${data.Function}` : ""}<br />
                    {data.Region}
                </p>
            </div>

        </div>
    )
}

export default MessageTileComponent