import { Helmet } from "react-helmet";
import {useLastMessagesQuery} from "../../../generated/graphql/graph.tsx";
import MessageListComponent from "../MessageListComponent";
import React from "react";
import {TitleSuffix} from "../../../utils/classNames.ts";


interface Props {
}

const LatestMessagesComponent: React.FC<Props> = () => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page') ?? "") || 1;
    const {data, loading, error} = useLastMessagesQuery({variables: {page: page, limit: 24}})
    return (
        <>
            <Helmet>
                <title>Laatste 112 meldingen  {TitleSuffix}</title>
                <link rel="canonical" href={`https://www.watskebeurt.nl/laatste-112-meldingen?page=${page}`}/>
            </Helmet>
            <MessageListComponent data={data?.LastMessages} loading={loading} error={error} appendRealtime={true} title="Laatste 112 meldingen" description="De laatste 112 meldingen die binnen zijn gekomen"/>
        </>

    )
}

export default LatestMessagesComponent