import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import {P2kMessage, PriorityNames, UnitNames} from "../../../models/message";
import moment from "moment";
import CapCodeTag from "../CapCodeTag";
import {ROUTE} from "../../../Services/Router/routes.tsx";
import useLinks from "../../../Services/Hooks/useLinks";

interface Props {
    content: P2kMessage;
    onClose: () => void;
}

const  DetailModal: React.FC<Props> = ({content, onClose}) => {
    const [open, setOpen] = useState(true)
        const links = useLinks()

    const cancelButtonRef = useRef(null)

    const handleClickClose = () => {
        setOpen(false)
        onClose();
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 dark:accent-slate-900">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg dark:bg-slate-800">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-slate-800">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                                                {content.Message}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <div className="flex flex-col">
                                                    <div className="flex">
                                                        <div className="text-slate-500 text-sm font-medium dark:text-slate-400 text-right p-1">Eendheid:</div>
                                                        <div className="text-slate-900 text-sm font-medium dark:text-slate-200 text-left p-1">{UnitNames[content.Priority.Category]}</div>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="text-slate-500 text-sm font-medium dark:text-slate-400 text-right p-1">Prioriteit:</div>
                                                        <div className="text-slate-900 text-sm font-medium dark:text-slate-200 text-left p-1">{PriorityNames[content.Priority.Priority]}</div>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="text-slate-500 text-sm font-medium dark:text-slate-400 text-right p-1">Wanneer:</div>
                                                        <div className="text-slate-900 text-sm font-medium dark:text-slate-200 text-left p-1">
                                                            {moment(content.Date).format("HH:mm, DD MMM")}<br/>
                                                            <a href={links.Format(ROUTE.MESSAGE_DETAIL, {"id": content.UUID})} className="truncate text-sm text-gray-500 dark:text-gray-300">&#10148; Meer
                                                                detail</a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-2">
                                                {content.ExtraReceivers.map((receiver, index) => (
                                                    <CapCodeTag receiver={receiver} key={index} />                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-slate-700">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        onClick={handleClickClose}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default DetailModal