import React, {useEffect, useState} from 'react'
import {useGetLastForFavoritesQuery} from "../../../generated/graphql/graph.tsx";
import MessageTileComponent from "../MessageTileComponent";
import {useSelector} from "react-redux";
import {RootState} from "../../../App/Redux/rootState.ts";
import {P2kMessage} from "../../../models/message.ts";

interface Props {
    capcodes: number[]
    city: string
    name: string
}


const  CapcodesOverviewComponent: React.FC<Props> = ({capcodes, name}) => {

    const {data, loading, error} = useGetLastForFavoritesQuery({variables: {page: 1, limit: 5, capcodes: capcodes}});
    const [messages, setMessages] = useState<P2kMessage[]>([])
    const feedMessage = useSelector((state: RootState) => state.pk2.feedMessage);


    useEffect(() => {
        if (data && data?.GetLastForFavorites && data.GetLastForFavorites.Messages.length > 0){
            setMessages(data.GetLastForFavorites.Messages)
        }
    }, [data, loading, error]);

    useEffect(() => {
        var isSet = false;
        if (feedMessage != null){
            if(feedMessage.ExtraReceivers && feedMessage.ExtraReceivers.length > 0) {
                feedMessage.ExtraReceivers.forEach((receiver) => {
                    const capcode: number = parseInt(receiver.CapCode, 10);
                    if (capcodes.includes(capcode)) {
                        if (!isSet) {
                            isSet = true;
                            setMessages([feedMessage, ...messages])
                        }
                    }
                });
            }
        }
    }, [feedMessage]);

    if (loading) {
        return (
            <div className="w-1/3">
                Loading...
            </div>
        )
    }

    return (
        <section className="m-5 rounded-md shadow p-5 w-1/3">
            <div>
                <h2 className="text-2xl font-bold">{name}</h2>
            </div>
            <div>
                {messages.map((msg, index) => (
                    <div key={index} className="pb-2">
                        <MessageTileComponent data={msg} loading={false}/>
                    </div>

                ))}
            </div>
            {/*{data?.GetLastForFavorites ?}*/}
        </section>
    )
}

export default CapcodesOverviewComponent