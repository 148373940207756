import React from "react";
import {useFindByCityQuery} from "../../../generated/graphql/graph.tsx";
import MessageListComponent from "../MessageListComponent";
import {useParams} from "react-router-dom";
import MapLocalComponent from "../MapLocalComponent";
import {Helmet} from "react-helmet";
import {TitleSuffix} from "../../../utils/classNames.ts";

interface Props {
}

const CityMessagesComponent: React.FC<Props> = () => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page') ?? "") || 1;
    const { city } = useParams();
    const {data, loading, error} = useFindByCityQuery({variables: {page: page, limit: 24, city: city}})

    return (
        <>
            <Helmet>
                <title>Plaats: {city}  {TitleSuffix}</title>
                <link rel="canonical" href={`https://www.watskebeurt.nl/plaatsen/${encodeURIComponent(city || "")}?page=${page}`}/>
            </Helmet>
            <MapLocalComponent css="w-full lg:h-96 z-0 hidden md:block md:h-40"/>
            <MessageListComponent data={data?.FindByCity} loading={loading} error={error} title={city} description={"112 meldingen in " + city}/>
        </>
    )
}

export default CityMessagesComponent