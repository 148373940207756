import React, {Fragment} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {Bars3Icon, BellIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {classNames} from "../../../utils/classNames.ts";
import {ROUTE} from "../../../Services/Router/routes.tsx";
import {NavLink, useLocation} from "react-router-dom";
import ZwaailichtIcon from "../../../svg/police-car-light-svgrepo-com.svg?react";

interface Props {
}


const NavBarComponent: React.FC<Props> = () => {

    const location = useLocation();

    const navigation = [
        { name: 'Kaart', href: ROUTE.ROOT, current: location.pathname == ROUTE.ROOT },
        { name: 'Laatste meldingen', href: ROUTE.LATEST, current: location.pathname == ROUTE.LATEST },
        { name: 'Plaatsen', href: ROUTE.CITIES, current: location.pathname == ROUTE.CITIES },
        { name: 'Alarmeer codes', href: ROUTE.CAP_CODES, current: location.pathname == ROUTE.CAP_CODE },
        { name: 'Telegram Bot', href: ROUTE.TELEGRAM_BOT, current: location.pathname == ROUTE.TELEGRAM_BOT },
        { name: 'Apps', href: ROUTE.APP, current: location.pathname == ROUTE.APP },
        { name: 'Status page', href: "https://stats.uptimerobot.com/y3tV0v6cZ6", current: false },
    ]

    return (
        <Disclosure as="nav" className="bg-white shadow fixed top-0 z-10 w-full dark:bg-gray-800">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex px-2 lg:px-0">
                                <div className="flex flex-shrink-0 place-items-center items-center logo text-xl">
                                    <a href="/" className="flex place-items-center text-black dark:text-slate-300">
                                        <span>Watskebeurt.nl</span>
                                        <ZwaailichtIcon className="h-5 w-5 ml-2"/>
                                    </a>
                                </div>
                                <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                                    {/* Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                    {navigation.map((item) => (
                                        <NavLink
                                            to={item.href}
                                            key={item.name}
                                            className={classNames("inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 border-b-2 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white", item.current ? "border-blue-500 text-gray-900" : "border-transparent hover:border-gray-300")}
                                        >
                                            {item.name}
                                        </NavLink>
                                    ))}
                                </div>
                            </div>
                            <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
                                <div className="w-full max-w-lg lg:max-w-xs">
                                    {/*P2000 meldingen*/}
                                    {/*<label htmlFor="search" className="sr-only">*/}
                                    {/*    Search*/}
                                    {/*</label>*/}
                                    {/*<div className="relative">*/}
                                    {/*    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">*/}
                                    {/*        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                                    {/*    </div>*/}
                                    {/*    <inputservice*/}
                                    {/*        id="search"*/}
                                    {/*        name="search"*/}
                                    {/*        className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"*/}
                                    {/*        placeholder="Search"*/}
                                    {/*        type="search"*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="flex items-center lg:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="hidden lg:ml-4 lg:flex lg:items-center">
                                <button
                                    type="button"
                                    className="relative flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:bg-gray-800 dark:text-gray-400 dark:hover:text-white dark:focus:ring-white dark:focus:ring-offset-gray-800"
                                >
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button>

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-4 flex-shrink-0">
                                    <div>
                                        <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                            <span className="absolute -inset-1.5" />
                                            <span className="sr-only">Open user menu</span>
                                            {/*<img*/}
                                            {/*    className="h-8 w-8 rounded-full"*/}
                                            {/*    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
                                            {/*    alt=""*/}
                                            {/*/>*/}
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                    >
                                                        Your Profile
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                    >
                                                        Settings
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                    >
                                                        Sign out
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="lg:hidden">
                        <div className="space-y-1 pb-3 pt-2">
                            {/* Current: "bg-blue-50 border-blue-500 text-blue-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
                            {/*<Disclosure.Button*/}
                            {/*    as="a"*/}
                            {/*    href="#"*/}
                            {/*    className="block border-l-4 border-blue-500 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700"*/}
                            {/*>*/}
                            {/*    Dashboard*/}
                            {/*</Disclosure.Button>*/}
                            {/*<Disclosure.Button*/}
                            {/*    as="a"*/}
                            {/*    href="#"*/}
                            {/*    className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"*/}
                            {/*>*/}
                            {/*    Team*/}
                            {/*</Disclosure.Button>*/}
                            {/*<Disclosure.Button*/}
                            {/*    as="a"*/}
                            {/*    href="#"*/}
                            {/*    className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"*/}
                            {/*>*/}
                            {/*    Projects*/}
                            {/*</Disclosure.Button>*/}
                            {/*<Disclosure.Button*/}
                            {/*    as="a"*/}
                            {/*    href="#"*/}
                            {/*    className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"*/}
                            {/*>*/}
                            {/*    Calendar*/}
                            {/*</Disclosure.Button>*/}
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    as="a"
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(item.current ? "block border-l-4 border-blue-500 dark:border-orange-500 bg-blue-50 dark:bg-slate-800 py-2 pl-3 pr-4 text-base font-medium text-blue-700 dark:text-white" : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800")}
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}

                        </div>
                        <div className="border-t border-gray-200 pb-3 pt-4">
                            <div className="flex items-center px-4">
                                {/*<div className="flex-shrink-0">*/}
                                {/*    <img*/}
                                {/*        className="h-10 w-10 rounded-full"*/}
                                {/*        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
                                {/*        alt=""*/}
                                {/*    />*/}
                                {/*</div>*/}
                                {/*<div className="ml-3">*/}
                                {/*    <div className="text-base font-medium text-gray-800">Tom Cook</div>*/}
                                {/*    <div className="text-sm font-medium text-gray-500">tom@example.com</div>*/}
                                {/*</div>*/}
                                <button
                                    type="button"
                                    className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:bg-gray-800 dark:text-gray-400 dark:hover:text-white dark:focus:ring-white dark:focus:ring-offset-gray-800"
                                >
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            {/*<div className="mt-3 space-y-1">*/}
                            {/*    <Disclosure.Button*/}
                            {/*        as="a"*/}
                            {/*        href="#"*/}
                            {/*        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"*/}
                            {/*    >*/}
                            {/*        Your Profile*/}
                            {/*    </Disclosure.Button>*/}
                            {/*    <Disclosure.Button*/}
                            {/*        as="a"*/}
                            {/*        href="#"*/}
                            {/*        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"*/}
                            {/*    >*/}
                            {/*        Settings*/}
                            {/*    </Disclosure.Button>*/}
                            {/*    <Disclosure.Button*/}
                            {/*        as="a"*/}
                            {/*        href="#"*/}
                            {/*        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"*/}
                            {/*    >*/}
                            {/*        Sign out*/}
                            {/*    </Disclosure.Button>*/}
                            {/*</div>*/}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
        // <Disclosure as="nav" className="bg-gray-800 fixed top-0 z-10 w-full">
        //     {({ open }) => (
        //         <>
        //             <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        //                 <div className="relative flex h-16 items-center justify-between">
        //                     <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
        //                         {/* Mobile menu button*/}
        //                         <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
        //                             <span className="absolute -inset-0.5" />
        //                             <span className="sr-only">Open main menu</span>
        //                             {open ? (
        //                                 <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
        //                             ) : (
        //                                 <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
        //                             )}
        //                         </Disclosure.Button>
        //                     </div>
        //                     <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
        //                         <div className="flex flex-shrink-0 items-center">
        //                             {/*<img*/}
        //                             {/*    className="h-8 w-auto"*/}
        //                             {/*    src="https://tailwindui.com/img/logos/mark.svg?color=blue&shade=500"*/}
        //                             {/*    alt="Your Company"*/}
        //                             {/*/>*/}
        //                         </div>
        //                         <div className="hidden sm:ml-6 sm:block">
        //                             <div className="flex space-x-4">
        //                                 {navigation.map((item) => (
        //                                     <a
        //                                         key={item.name}
        //                                         href={item.href}
        //                                         className={classNames(
        //                                             item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
        //                                             'rounded-md px-3 py-2 text-sm font-medium'
        //                                         )}
        //                                         aria-current={item.current ? 'page' : undefined}
        //                                     >
        //                                         {item.name}
        //                                     </a>
        //                                 ))}
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        //                         <button
        //                             type="button"
        //                             className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
        //                         >
        //                             <span className="absolute -inset-1.5" />
        //                             <span className="sr-only">View notifications</span>
        //                             <BellIcon className="h-6 w-6" aria-hidden="true" />
        //                         </button>
        //
        //                         {/*/!* Profile dropdown *!/*/}
        //                         {/*<Menu as="div" className="relative ml-3">*/}
        //                         {/*    <div>*/}
        //                         {/*        <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">*/}
        //                         {/*            <span className="absolute -inset-1.5" />*/}
        //                         {/*            <span className="sr-only">Open user menu</span>*/}
        //                         {/*            <img*/}
        //                         {/*                className="h-8 w-8 rounded-full"*/}
        //                         {/*                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
        //                         {/*                alt=""*/}
        //                         {/*            />*/}
        //                         {/*        </Menu.Button>*/}
        //                         {/*    </div>*/}
        //                         {/*    <Transition*/}
        //                         {/*        as={Fragment}*/}
        //                         {/*        enter="transition ease-out duration-100"*/}
        //                         {/*        enterFrom="transform opacity-0 scale-95"*/}
        //                         {/*        enterTo="transform opacity-100 scale-100"*/}
        //                         {/*        leave="transition ease-in duration-75"*/}
        //                         {/*        leaveFrom="transform opacity-100 scale-100"*/}
        //                         {/*        leaveTo="transform opacity-0 scale-95"*/}
        //                         {/*    >*/}
        //                         {/*        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">*/}
        //                         {/*            <Menu.Item>*/}
        //                         {/*                {({ active }) => (*/}
        //                         {/*                    <a*/}
        //                         {/*                        href="#"*/}
        //                         {/*                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}*/}
        //                         {/*                    >*/}
        //                         {/*                        Your Profile*/}
        //                         {/*                    </a>*/}
        //                         {/*                )}*/}
        //                         {/*            </Menu.Item>*/}
        //                         {/*            <Menu.Item>*/}
        //                         {/*                {({ active }) => (*/}
        //                         {/*                    <a*/}
        //                         {/*                        href="#"*/}
        //                         {/*                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}*/}
        //                         {/*                    >*/}
        //                         {/*                        Settings*/}
        //                         {/*                    </a>*/}
        //                         {/*                )}*/}
        //                         {/*            </Menu.Item>*/}
        //                         {/*            <Menu.Item>*/}
        //                         {/*                {({ active }) => (*/}
        //                         {/*                    <a*/}
        //                         {/*                        href="#"*/}
        //                         {/*                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}*/}
        //                         {/*                    >*/}
        //                         {/*                        Sign out*/}
        //                         {/*                    </a>*/}
        //                         {/*                )}*/}
        //                         {/*            </Menu.Item>*/}
        //                         {/*        </Menu.Items>*/}
        //                         {/*    </Transition>*/}
        //                         {/*</Menu>*/}
        //                     </div>
        //                 </div>
        //             </div>
        //
        //             <Disclosure.Panel className="sm:hidden">
        //                 <div className="space-y-1 px-2 pb-3 pt-2">
        //                     {navigation.map((item) => (
        //                         <Disclosure.Button
        //                             key={item.name}
        //                             as="a"
        //                             href={item.href}
        //                             className={classNames(
        //                                 item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
        //                                 'block rounded-md px-3 py-2 text-base font-medium'
        //                             )}
        //                             aria-current={item.current ? 'page' : undefined}
        //                         >
        //                             {item.name}
        //                         </Disclosure.Button>
        //                     ))}
        //                 </div>
        //             </Disclosure.Panel>
        //         </>
        //     )}
        // </Disclosure>
    )
}

export default NavBarComponent;
