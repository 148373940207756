import React from "react";
import FireIcon from "../../../svg/fire-solid.svg?react";
import PoliceIcon from "../../../svg/siren-on-duotone.svg?react";
import AmbuIcon from "../../../svg/truck-medical-solid.svg?react";
import HelicopterIcon from "../../../svg/helicopter-solid.svg?react";
import UnknownIcon from "../../../svg/comment-solid.svg?react";

import {MapContainer, Marker, TileLayer} from "react-leaflet";
import {Icon} from "leaflet";
import CapCodeTag from "../CapCodeTag";
import moment from "moment";
import {P2kExtraReceiver, P2kMessage, P2kMessagePriorityUnit, PriorityNames} from "../../../models/message.ts";
import {NavLink} from "react-router-dom";
import {ROUTE} from "../../../Services/Router/routes.tsx";
import useLinks from "../../../Services/Hooks/useLinks";

interface Props {
    data: P2kMessage | null;
    loading: boolean
}

const MessageTileComponent: React.FC<Props> = ({data, loading}) => {

    const now = moment()
    const links = useLinks()
    const [showRaw, setShowRaw] = React.useState<boolean>(false)

    const clickIcon = () => {
        window.gtag('event', 'action', {
            'event_category': 'UserClick',
            'event_label': 'show_raw',
            'value': !showRaw
        });

        setShowRaw(!showRaw)
    }

    if (loading) {
        return (
            <div
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:border-gray-400 dark:bg-gray-800 dark:border-slate-700">
                <div className="flex-shrink-0 flex">
                    <AmbuIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />
                </div>
                <div className="min-w-0 flex-1">
                    <p className="text-sm font-medium text-gray-900 animate-pulse">Loading..&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <p className="truncate text-sm text-gray-500 animate-pulse">Loading</p>
                    <div className="mt-5">
                    </div>
                </div>
            </div>
        )
    }

    if (data == null) {
        return (
            <></>
        )
    }


    return (
        <div
            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:border-gray-400 dark:bg-gray-800 dark:border-slate-700"
        >
            <div className="flex-shrink-0 flex">
                <button onClick={clickIcon} aria-label="Klik hier voor het originele bericht">
                {data?.Priority && data.Priority.Category == P2kMessagePriorityUnit.Ambulance && <AmbuIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                {data?.Priority && data.Priority.Category == P2kMessagePriorityUnit.FireDepartment && <FireIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                {data?.Priority && data.Priority.Category == P2kMessagePriorityUnit.Police && <PoliceIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                {data?.Priority && data.Priority.Category == P2kMessagePriorityUnit.Unknown && <UnknownIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                {data?.Priority && data.Priority.Category == P2kMessagePriorityUnit.LifeLiner && <HelicopterIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                </button>
            </div>
            <div className="min-w-0 flex-1">
                <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {!showRaw && data.Message}
                    {showRaw && data.RawBody}
                </p>
                <p className="truncate text-sm text-gray-500 dark:text-gray-300">{data?.Location?.City && data.Location.City != "" && (
                    <>
                    {data.Priority.Priority ? PriorityNames[data.Priority.Priority] + " - " : ""}
                    <NavLink to={links.Format(ROUTE.CITY, {'city': data.Location.City})}>
                        {data.Location.City}
                    </NavLink>
                        &nbsp;-&nbsp;
                    </>
                )}{now.diff(data.Date) > (3600 * 1000) ? moment(data.Date).format("DD/MM/YYYY - HH:mm") :  moment(data.Date).fromNow()}</p>
                <a href={links.Format(ROUTE.MESSAGE_DETAIL, {"id": data.UUID})} className="truncate text-sm text-gray-500 dark:text-gray-300">&#10148; Meer detail</a>
                <div className="mt-5">
                    {data.ExtraReceivers?.map((receiver: P2kExtraReceiver) =>
                        (
                            <CapCodeTag receiver={receiver} key={receiver.CapCode} />

                        ))}
                </div>

                <div className="w-100 h-100">
                    {data?.Location && data.Location.Lat != 0 && data.Location.Long != 0 && <MapContainer attributionControl={false} center={[data.Location.Lat , data.Location.Long]} zoom={8} zoomControl={false} scrollWheelZoom={false} className="w-full h-full small-map" dragging={false} touchZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[data.Location.Lat , data.Location.Long]}
                                icon={new Icon({
                            iconUrl: "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png",
                            iconSize: [25, 41],
                            iconAnchor: [12, 41],
                            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                            shadowSize: [41, 41],
                            popupAnchor: [1, -34]
                        })}/>
                    </MapContainer>}
                </div>
            </div>
        </div>
    )
}

export default MessageTileComponent