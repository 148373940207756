import React from "react";
import { useGetCitiesQuery} from "../../../generated/graphql/graph.tsx";
import useLinks from "../../../Services/Hooks/useLinks";
import {ROUTE} from "../../../Services/Router/routes.tsx";
import FindCityComponent from "../FindCityComponent";
import { Helmet } from "react-helmet";
import {TitleSuffix} from "../../../utils/classNames.ts";

interface Props {
}

const CitiesComponent: React.FC<Props> = () => {
    const {data, loading, error} = useGetCitiesQuery()
    const links = useLinks()
    return (
        <div className="mt-24 mb-24 mx-auto max-w-7xl px-2 sm:px-4 lg:px-8 h-auto">
            <Helmet>
                <title>Vind P2000 berichten voor uw plaats!  {TitleSuffix}</title>
                <link rel="canonical" href="https://www.watskebeurt.nl/plaatsen"/>
            </Helmet>
            <div className="pb-24">
                <div className="mb-12">
                    <h1 className="text-3xl sm:text-4xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200">Plaatsen</h1>
                    <span className="text-l text-slate-700 dark:text-slate-400">Lijst met alle plaatsen</span>
                </div>
                <FindCityComponent data={data?.GetCities}/>
                {!loading && !error && <ul className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {data?.GetCities?.map((city) => (
                        <li key={city.Abbreviation + city.Name}>
                            <a href={links.Format(ROUTE.CITY, {'city': city.Name})}  className="font-medium text-slate-700 hover:text-slate-500 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">{city.Name}</a>
                        </li>
                    ))}
                </ul>}
            </div>
        </div>
    )
}

export default CitiesComponent