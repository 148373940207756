import React, {useEffect, useState} from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import {classNames} from "../../../utils/classNames.ts";
import {ICity} from "../../../models/message.ts";

interface Props {
data?: ICity[]
}

const FindCityComponent: React.FC<Props> = ({data}) =>  {
    const [query, setQuery] = useState('')
    const [selectedCity, setSelectedCity] = useState<ICity|null>(null)
    const [cities, setCities] = useState<ICity[]>([])

    useEffect(() => {
        if (data != null) {
            setCities(data)
        }
    })

    const onChange = (event: ICity | null) => {
        setSelectedCity(event)
        if (event != null) {
            window.location.href = '/plaatsen/' + event.Name
        }
    }

    const filteredCities = () : ICity[] => {
        let filtered: ICity[] = []
        if (query.length <= 2) {
            return filtered
        }
        window.gtag('event', 'action', {
            'event_category': 'UserEvent',
            'event_label': 'filter_cities',
            'value': query
        });
        for (let city of cities) {
            if (city.Name.toLowerCase().includes(query.trim().toLowerCase())) {
                filtered.push(city)
            }
        }
        return filtered
    }

    return (
        <Combobox as="div" value={selectedCity} onChange={onChange} className="mb-10">
            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Zoek uw plaats</Combobox.Label>
            <div className="relative mt-2">
                <Combobox.Input
                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:text-white dark:ring-slate-700"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(city : ICity) => city?.Name}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>

                {filteredCities().length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-slate-600">
                        {filteredCities().map((city) => (
                            <Combobox.Option
                                key={city.Abbreviation + city.Name}
                                value={city}
                                className={({ active }) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        active ? 'bg-blue-600 text-white dark:bg-slate-600' : 'text-gray-900 dark:bg-slate-800 dark:text-white'
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span className={classNames('block truncate', (selectedCity?.Name ? 'font-semibold' : ''))}>{city.Name}</span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    active ? 'text-white' : 'text-blue-600'
                                                )}
                                            >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    )
}

export default FindCityComponent