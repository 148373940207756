import {combineReducers, configureStore} from "@reduxjs/toolkit";
// import storage from "redux-persist/lib/storage";
import p2k from "./slices/p2k";

// const persistConfig = {
//     key: 'root',
//     // storage,
// };

const reducers = combineReducers({
    pk2: p2k,
});
// const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV !== 'production',
    // middleware: [thunk],

})

// export const persistor = persistStore(store);