import React, {useEffect, useState} from "react";
import {P2kExtraReceiver} from "../../../models/message";
import {classNames} from "../../../utils/classNames";
import {NavLink} from "react-router-dom";
import useLinks from "../../../Services/Hooks/useLinks";
import {ROUTE} from "../../../Services/Router/routes.tsx";

interface CapCodeTagProps {
    receiver: P2kExtraReceiver;
}

const CapCodeTag: React.FC<CapCodeTagProps> = ({receiver}) => {

    const [color, setColor] = useState<string>('bg-gray-500/20');
    const formatLinks = useLinks()

    useEffect(() => {
        switch (receiver.Discipline) {
            case 'BRW':
            case 'GMK':
                setColor('bg-red-100');
                break;
            case 'AMBU':
                setColor('bg-yellow-100');
                break;
            case 'POL':
                setColor('bg-blue-100');
                break;
        }
    }, [receiver]);

    return (
        <NavLink to={formatLinks.Format(ROUTE.CAP_CODE, {"cap": receiver.CapCode})}>
            <span className={classNames("inline-flex items-center rounded-full  px-2 py-1 mr-2 mb-2 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10", color)}  key={receiver.CapCode}>
                  {receiver.Function ? receiver.Function + ' - ' : ''} {receiver.CapCode}
            </span>
        </NavLink>
    )
}

export default CapCodeTag;