import React from 'react'

interface Props {
}

const  PrivacyComponent: React.FC<Props> = () => {

    return (
        <div className="mt-16 mb-24 mx-auto max-w-7xl px-2 sm:px-4 lg:px-8 h-auto lg:mt-24 p-10 bg-white rounded-md shadow">
            <div className="px-4 sm:px-0">
                <h1 className="text-base font-semibold leading-7 text-gray-900">Privacy policy</h1>
                Deze website verzamel geen persoonlijke gegevens.
                enkel gegevens voor het functioneren van de website en het bijhouden van statistieken.
            </div>
        </div>
    )
}

export default PrivacyComponent