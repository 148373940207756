import React, {ReactNode} from "react";
import FeedInputComponent from "../../FeedInputComponent";
import NavBarComponent from "../../content/NavBar";

interface Props {
    children: ReactNode;
}

const MenuPage: React.FC<Props> = ({children}) => {
    return (
        <>
            <FeedInputComponent />
            <NavBarComponent />
            {children}
        </>
    )
}

export default MenuPage