import React, {useEffect} from "react";
import {useFindByCapCodeQuery, useGetCapCodeQuery} from "../../../generated/graphql/graph.tsx";
import MessageListComponent from "../MessageListComponent";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {TitleSuffix} from "../../../utils/classNames.ts";

interface Props {
}

const CapMessagesComponent: React.FC<Props> = () => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page') ?? "") || 1;
    const { cap } = useParams();
    const capCode = parseInt(cap ?? "0")
    const {data, loading, error} = useFindByCapCodeQuery({variables: {page: page, limit: 24, capCode: capCode}})
    const {data: capData } = useGetCapCodeQuery({variables: {capCode: capCode}})
    const [description, setDescription] = React.useState<string>("")

    useEffect(() => {
        if (capData?.GetCapCode){
            setDescription(capData.GetCapCode.Function)
        }
    }, [cap, capData]);

    return (
        <>
            <Helmet>
                <title>CapCode {cap} {TitleSuffix}</title>
                <meta name="description" content={`Alle berichten van cap code / 112 alarmeer code: ${capCode}`}/>
                <link rel="canonical" href={`https://www.watskebeurt.nl/alarmcodes/${capCode}?page=${page}`}/>
            </Helmet>
            <MessageListComponent data={data?.FindByCapCode} loading={loading} error={error} title={"Berichten 112 bericht code (cap code) - " + cap +""} description={description}/>
        </>
    )
}

export default CapMessagesComponent