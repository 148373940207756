import { useEffect} from "react";
import {P2kMessage, P2KMessageCommandType} from "../../models/message";
import {useDispatch, useSelector} from "react-redux";
import {addMessage, setLatestMessages} from "../../App/Redux/slices/p2k";
import {RootState} from "../../App/Redux/rootState.ts";
import {useLastMessagesQuery} from "../../generated/graphql/graph.tsx";

const FeedInputComponent = () => {

    const dispatch = useDispatch();
    const showPing = useSelector((state: RootState) => state.pk2.showPing);
    const {data} = useLastMessagesQuery({variables: {page: 0, limit: 50}})

    const getWsURL = (): string => {
        let wsUrl = import.meta.env.WS_URL || "wss://" + document.location.host +"/ws"

        if (!wsUrl.startsWith("ws")) {
            let protocol = document.location.protocol === "https:" ? "wss://" : "ws://";
            let host = document.location.host;
            let path = wsUrl
            wsUrl = protocol + host + path;
        }
        return wsUrl

    }
    useEffect(() => {
        if ( data?.LastMessages?.Messages) {
            for (const message of data.LastMessages.Messages || []) {
                dispatch(addMessage(message as P2kMessage))
            }
        }

        let wsUrl = getWsURL()
        console.log("Connecting to websocket")
        const socket = new WebSocket(wsUrl);
        socket.onopen = () => {
            console.log('WebSocket connection opened');
        };
        socket.onclose = () => {
            console.log('WebSocket connection closed');
        };
        socket.onmessage = (event) => {
            const data = event.data;
            const message : P2kMessage = JSON.parse(data)
            switch (message.Type) {
                case P2KMessageCommandType.Command:
                    window.gtag('event', 'action', {
                        'event_category': 'ApplicationEvent',
                        'event_label': 'got_ping',
                        'value': 'ping'
                    });

                    if (message.Command.Command === "PING") {
                        if (showPing) {
                            console.log("PING")
                        }
                    } else {
                        console.log("Command: " + message.Command.Command + " not implemented.")
                    }
                    break;
                case P2KMessageCommandType.P2000:
                    window.gtag('event', 'action', {
                        'event_category': 'ApplicationEvent',
                        'event_label': 'got_message',
                        'value': message.ID
                    });
                    dispatch(addMessage(message))
                    dispatch(setLatestMessages(message))
                    break;
                default:
                    window.gtag('event', 'action', {
                        'event_category': 'ApplicationEvent',
                        'event_label': 'Unkonwn_message_type',
                        'value': message.Type
                    });
                    console.log("Unknown message type received (" + message.Type + ").")
                    console.log(message)
            }
        };
    }, [data])


    return (
        <>
        </>
    )
}

export default FeedInputComponent;