import {MapContainer, Marker, TileLayer} from "react-leaflet";
import React, {useEffect, useState} from "react";
import {Icon} from "leaflet";
import DetailModal from "../DetailModalComponent";
import {P2kMessage} from "../../../models/message";
import {useFindByCityQuery} from "../../../generated/graphql/graph.tsx";
import {useParams} from "react-router-dom";

interface Props {
    css: string;
}

const MapLocalComponent: React.FC<Props> = ({css}) => {
    const [showModal, setShowModal] = useState(false);
    const [markers, setMarkers] = useState<P2kMessage[]>([]);
    const { city } = useParams();
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page') ?? "") || 1;
    const toggleModal = () => {
        setShowModal(!showModal)
    }
    const [selectedMessage, setSelectedMessage] = useState<P2kMessage | undefined>(undefined)
    const [center, setCenter] = useState([0, 0]);


    let {data} = useFindByCityQuery({variables: {page: page, limit: 24, city: city}})

    useEffect(() => {
        if (data?.FindByCity?.Messages) {
            // @ts-ignore
            setMarkers(data.FindByCity.Messages);
        }
        if (data?.FindByCity?.GPSLocation) {
            setCenter([data.FindByCity.GPSLocation.Lat, data.FindByCity.GPSLocation.Long]);
        }
    }, [data]);

    const markerIcons: { [key: number]: string } = {
        0: "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png",
        1: "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png",
        2: "https://cdn.jsdelivr.net/gh/pointhi/leaflet-color-markers@master/img/marker-icon-2x-red.png",
        3: "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
        4: "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png",
    }

    if (center[0] === 0 && center[1] === 0) {
        return (<></>)
    }

    return (
        <div className="mb-8">
            {/*@ts-ignore*/}
            <MapContainer center={center} zoom={12} scrollWheelZoom={true} zoomControl={false} className={css} attributionControl={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {markers.map((position, index) => (
                    (<Marker
                        key={position.ID}
                        icon={new Icon({
                            iconUrl: markerIcons[position.Priority.Category],
                            iconSize: [25, 41],
                            iconAnchor: [12, 41],
                            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                            shadowSize: [41, 41],
                            popupAnchor: [1, -34]
                        })}
                        position={[position.Location?.Lat || 0, position.Location?.Long || 0]}
                        eventHandlers={{
                            click: () => {
                                setSelectedMessage(markers[index]);
                                toggleModal();
                            },
                        }}
                        >
                    </Marker>)
                ))}
            </MapContainer>
            {showModal && <DetailModal onClose={toggleModal} content={selectedMessage!}/>}
        </div>
    )
}

export default MapLocalComponent;