import React from "react";
import {useGetCapCodesQuery} from "../../../generated/graphql/graph.tsx";
import CapTileComponent from "../CapTileComponent";
import PaginationComponent from "../PaginationComponent";
import {Helmet} from "react-helmet";
import {TitleSuffix} from "../../../utils/classNames.ts";

interface Props {
}

const CapCodesComponent: React.FC<Props> = () => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page') ?? "") || 1;
    const {data, loading, error} = useGetCapCodesQuery({variables: {page: page, limit: 24}})
    return (
        <div className="mt-24 mb-24 mx-auto max-w-7xl px-2 sm:px-4 lg:px-8 h-auto">
            <Helmet>
                <title>Alarmeer codes  {TitleSuffix}</title>
                <link rel="canonical" href={`https://www.watskebeurt.nl/alarmcodes`}/>
            </Helmet>

            <div className="pb-24">
                <div className="mb-12">
                    <h1 className="text-3xl sm:text-4xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200">Alarmeer codes</h1>
                    <span className="text-l text-slate-700 dark:text-slate-400">Lijst met bij ons bekende alarmeer codes</span>
                </div>
                {!loading && !error && <ul className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {data?.GetCapCodes?.CapCodes.map((cap) => (
                        <CapTileComponent data={cap} key={cap.CapCode}/>
                    ))}
                </ul>}
            </div>
            <PaginationComponent total={data?.GetCapCodes.Total ?? 0} pageSize={data?.GetCapCodes.Limit ?? 24} page={data?.GetCapCodes.Page ?? 1} />
        </div>
    )
}

export default CapCodesComponent