import {P2kMessage} from "../../../models/message";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface P2kMessagesState {
    messages: P2kMessage[];
    markers: P2kMessage[];
    lastMessage: P2kMessage | null
    feedMessage: P2kMessage | null;
    showPing: boolean;
}

const initialState: P2kMessagesState = {
    messages: [],
    lastMessage: null,
    markers: [],
    showPing: false,
    feedMessage: null,
}

const max_events = 50;

const p2kSlice = createSlice({
    name: 'p2k',
    initialState,
    reducers: {
        addMessage(state, action: PayloadAction<P2kMessage>) {
            state.messages.unshift(action.payload)
            if (action.payload.Location !== null) {
                state.markers.unshift(action.payload)
            }
            if (state.messages.length > max_events) {
                state.messages.pop()
            }
            if (state.markers.length > max_events) {
                state.markers.pop()
            }
            state.lastMessage = action.payload;
        },
        togglePing(state) {
            state.showPing = !state.showPing
        },
        setLatestMessages(state, action: PayloadAction<P2kMessage>) {
            state.feedMessage = action.payload;
        }
    }
});

export const {addMessage, setLatestMessages} = p2kSlice.actions;

export default p2kSlice.reducer;