/// <reference types="vite-plugin-svgr/client" />
import {useSelector} from "react-redux";
import {RootState} from "../../../App/Redux/rootState";
import FireIcon from "../../../svg/fire-solid.svg?react";
import PoliceIcon from "../../../svg/siren-on-duotone.svg?react";
import AmbuIcon from "../../../svg/truck-medical-solid.svg?react";
import HelicopterIcon from "../../../svg/helicopter-solid.svg?react";
import UnknownIcon from "../../../svg/comment-solid.svg?react";
import {P2kMessagePriorityUnit, PriorityNames} from "../../../models/message";

import moment from "moment";
import CapCodeTag from "../CapCodeTag";
import React from "react";
import {ROUTE} from "../../../Services/Router/routes.tsx";
import useLinks from "../../../Services/Hooks/useLinks";

const FeedComponent = () => {

    const message = useSelector((state: RootState) => state.pk2.lastMessage);
    const links = useLinks()
    const [showRaw, setShowRaw] = React.useState<boolean>(false)

    const clickIcon = () => {
        window.gtag('event', 'action', {
            'event_category': 'UserClick',
            'event_label': 'show_raw',
            'value': !showRaw
        });
        setShowRaw(!showRaw)
    }


    if (message === null) {
        return (
            <></>
        )
    }

    return (
        <div className="w-full flex justify-center absolute bottom-10 h-auto z-10">
            <div className="overflow-y-auto bg-white dark:bg-slate-800 rounded-xl w-[90%] min-h-24 flex flex-row">
                <div className="min-h-full flex justify-center items-center p-5">
                    <button onClick={clickIcon} aria-label="Show raw content">
                        {message.Priority.Category == P2kMessagePriorityUnit.Ambulance && <AmbuIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                        {message.Priority.Category == P2kMessagePriorityUnit.FireDepartment && <FireIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                        {message.Priority.Category == P2kMessagePriorityUnit.Police && <PoliceIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                        {message.Priority.Category == P2kMessagePriorityUnit.Unknown && <UnknownIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                        {message.Priority.Category == P2kMessagePriorityUnit.LifeLiner && <HelicopterIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                    </button>
                </div>
                <div className="p-5 flex flex-col">
                    <strong className="text-slate-900 text-sm font-medium dark:text-slate-200">
                        <h1 className="font-marker mb-2">Live de laatste 112 meldingen / alarmeringen</h1>
                        {!showRaw && message.Message}
                        {showRaw && message.RawBody}
                        <br/><a href={links.Format(ROUTE.MESSAGE_DETAIL, {"id": message.UUID})} className="truncate text-sm text-gray-500 dark:text-gray-300">&#10148; Meer detail</a>
                    </strong>
                    <span className="text-slate-500 text-sm font-medium dark:text-slate-400">{moment(message.Date).format("HH:mm DD MMM")}{message.Location?.City ? ' - ' + message.Location?.City : ''}{message.Priority.Priority ? ' - ' + PriorityNames[message.Priority.Priority] : ''}</span>
                    <div className="py-2">
                        {message.ExtraReceivers && message.ExtraReceivers.map((receiver, index) => (
                            <CapCodeTag receiver={receiver} key={index} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedComponent;