import React from 'react'
import CapcodesOverviewComponent from "../CapcodesOverviewComponent";

interface Props {
}

const  CastricumComponent: React.FC<Props> = () => {

    return (
        <div className="mt-16 mb-24 mx-auto w-screen px-2 sm:px-4 lg:px-8 h-auto lg:mt-24 p-5 bg-white rounded-md shadow">
            <div className="flex flex-row items-start justify-between items-">
                <CapcodesOverviewComponent city="heemskerk" capcodes={[200000, 1735850]} name="Heemskerk"/>
                <CapcodesOverviewComponent city="castricum" capcodes={[200000, 201347, 201357, 201358, 1735801, 1735802]} name="Castricum"/>
                <CapcodesOverviewComponent city="egmond" capcodes={[200000, 201244, 201254, 201255, 1735806, 1735807]} name="Egmond"/>
            </div>
        </div>
    )
}

export default CastricumComponent