import React from "react";
import {useParams} from "react-router-dom";
import {useGetMessageQuery} from "../../../generated/graphql/graph.tsx";
import moment from "moment";
import 'moment/dist/locale/nl';
import CapCodeTag from "../CapCodeTag";
import MapDetailComponent from "../MapDetailComponent";
import {P2kMessagePriorityUnit, PriorityNames} from "../../../models/message.ts";
import {Helmet} from "react-helmet";
import {TitleSuffix} from "../../../utils/classNames.ts";
import FireIcon from "../../../svg/fire-solid.svg?react";
import PoliceIcon from "../../../svg/siren-on-duotone.svg?react";
import AmbuIcon from "../../../svg/truck-medical-solid.svg?react";
import HelicopterIcon from "../../../svg/helicopter-solid.svg?react";
import UnknownIcon from "../../../svg/comment-solid.svg?react";
interface Props {
}

const MessageDetailComponent: React.FC<Props> = () =>  {
    const { id } = useParams();
    const isUUID: boolean = /(\-+)/.test(id || "");
    const ID = parseInt(id!)

    const formatTime = (parseTime: number): string => {
        let rounded = parseTime.toFixed(3)
        return rounded.toString().replace(".", ",")
    }

    const localeMoment = (parse: string): moment.Moment => {
        moment.locale('nl')
        const m =  moment(parse)
        moment.locale('nl-nl')
        return m
    }


    const {data, loading, error} = useGetMessageQuery({variables: {uuid: (isUUID ? id : undefined), id: (!isUUID ? ID: undefined)}})

    if (!data?.GetMessage || loading || error) {
        return (
            <></>
        )
    }

    return (
        <div className="mt-16 mb-24 mx-auto max-w-7xl px-2 sm:px-4 lg:px-8 h-auto lg:mt-24 p-10 bg-white rounded-md shadow">
            <Helmet>
                <title>{data?.GetMessage.RawBody} {TitleSuffix}</title>
                <meta name="description" content={`Details voor 112 bericht: ${data?.GetMessage.RawBody}`}/>
                <link rel="canonical" href={`https://www.watskebeurt.nl/bericht/${id}`}/>
            </Helmet>
            <div className="float-right">
                {data.GetMessage.Priority.Category == P2kMessagePriorityUnit.Ambulance && <AmbuIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                {data.GetMessage.Priority.Category == P2kMessagePriorityUnit.FireDepartment && <FireIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                {data.GetMessage.Priority.Category == P2kMessagePriorityUnit.Police && <PoliceIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                {data.GetMessage.Priority.Category == P2kMessagePriorityUnit.Unknown && <UnknownIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
                {data.GetMessage.Priority.Category == P2kMessagePriorityUnit.LifeLiner && <HelicopterIcon className="h-10 w-10 fill-blue-500" aria-hidden="true" />}
            </div>
            <div className="px-4 sm:px-0">
                <h2>Detail 112 melding</h2>
                <h1 className="text-base font-semibold leading-7 text-gray-900">{data.GetMessage.Message}</h1>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500"></p>
            </div>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Omschrijving</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.GetMessage.Priority.Category == P2kMessagePriorityUnit.Ambulance &&
                                <>
                                    <p>Een ambulance is op {localeMoment(data.GetMessage.ReceivedAt).format("LL")} om {localeMoment(data.GetMessage.ReceivedAt).format("HH:mm")} gestuurd naar een 112 alarmerings melding in {data.GetMessage.Location?.City} {data.GetMessage.Location?.Street.length ? " in de " + data.GetMessage.Location?.Street : ""}.</p>
                                    {data.GetMessage.Dia && <p className="pt-4"> De ambulance is met spoed uitgerukt naar deze melding via een "Directe Inzet Ambulance" melding. Dit betekent dat de centralist nog bezig is met het uitvragen (triage) van de melder maar de ambulance is al aanrijdend.</p>}
                                </>
                            }
                            {data.GetMessage.Priority.Category == P2kMessagePriorityUnit.FireDepartment &&
                                <>
                                    <p>De brandweer is op {localeMoment(data.GetMessage.ReceivedAt).format("LL")} om {localeMoment(data.GetMessage.ReceivedAt).format("HH:mm")} gestuurd naar een 112 alarmerings melding in {data.GetMessage.Location?.City} {data.GetMessage.Location?.Street.length ? " in de " + data.GetMessage.Location?.Street : ""}.</p>
                                </>
                            }
                            {data.GetMessage.Priority.Category == P2kMessagePriorityUnit.Police &&
                                <>
                                    <p>De politie is op {localeMoment(data.GetMessage.ReceivedAt).format("LL")} om {localeMoment(data.GetMessage.ReceivedAt).format("HH:mm")} gestuurd naar een 112 alarmerings melding in {data.GetMessage.Location?.City} {data.GetMessage.Location?.Street.length ? " in de " + data.GetMessage.Location?.Street : ""}.</p>
                                </>
                            }
                            <p className="pt-4">
                                De melding is verstuurd via het P2000 netwerk. Het P2000 netwerk is een communicatie netwerk waarover de 112 meldingen worden
vestuurd naar de juiste hulpdiensten. Dit kan Zijn Brandweer, Ambulance, Politie of KNRM/Reddings Brigade.
                            </p>
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Ontvangen op</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{moment(data.GetMessage.ReceivedAt).format("YYYY-MM-DD HH:mm")}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Prioriteit</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data.GetMessage.Priority.Priority ? PriorityNames[data.GetMessage.Priority.Priority] : ''}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Locatie</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data.GetMessage.Location?.Street ? data.GetMessage.Location?.Street + ' - ' : ''} {data.GetMessage.Location?.City}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Ontvanger</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data.GetMessage.Receiver}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Extra Ontvangers</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.GetMessage.ExtraReceivers.map((receiver, index) => (
                                <CapCodeTag receiver={receiver} key={index}/>
                            ))}
                        </dd>
                    </div>
                    {data.GetMessage.Priority.Category == P2kMessagePriorityUnit.Ambulance &&
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900"> Directe Inzet Ambulance</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {data.GetMessage.Dia ? "Ja" : "Nee"}
                            </dd>
                        </div>
                    }
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Origineel bericht</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data.GetMessage.RawBody}</dd>
                    </div>
                    {data.GetMessage.Location &&
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Kaart</dt>
                            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <MapDetailComponent message={data.GetMessage}/>
                            </dd>
                        </div>
                    }
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Verwerkingstijd</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{formatTime(data.GetMessage.ParseTime)} Seconden</dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}
export default MessageDetailComponent